import { useTheme } from '@emotion/react';

import { IconQuickMenuNewBadge } from '@/assets/svgs';
import { TrackClickEvent } from '@/components/common/EventClient/TrackClickEvent';
import type { QuickMenuItem as QuickMenuItemType } from '@/models/backendsApi/v2/Views/ViewsType';

import { useSectionTrackingDataContext } from '../../common/SectionTrackingDataContextProvider';
import * as styles from './QuickMenu.styles';

/**
 * QuickMenu만 따로 UA 에 보내는 interface가 다름
 *
 * @param HomeQuickMenuItem
 * @returns ReactJSX.Element
 */
export const QuickMenuItem = ({ items }: { items: QuickMenuItemType }): ReactJSX.Element => {
  const sectionTrackingData = useSectionTrackingDataContext();
  const { colorScheme } = useTheme();

  return (
    <li css={styles.quickmenuItemStyle}>
      <TrackClickEvent
        screenName={sectionTrackingData.screenName}
        target="quickmenu"
        params={{
          ...sectionTrackingData.params,
          quickmenu_title: items.contents.title,
          quickmenu_id: items.id,
          landing_url: items.contents.landing_url,
        }}>
        <a css={styles.quickmenuAnchorStyle} href={items.contents.landing_url} aria-label={items.contents.title}>
          <div css={styles.quickmenuImageWrapperStyle}>
            <img
              css={styles.quickmenuImageStyle}
              alt={items.contents.title}
              src={items.contents.main_image_url[colorScheme]}
            />
            {items.contents.quick_menu === 'KooKyoHwan' && (
              <IconQuickMenuNewBadge aria-label="신규 메뉴" css={styles.quickMenuNewBadgeStyle} />
            )}
          </div>
          <span css={styles.quickmenuLabelStyle}>{items.contents.title}</span>
        </a>
      </TrackClickEvent>
    </li>
  );
};
